import React from 'react'
import { footerLinks } from '../constants'

const Footer = () => {
  return (
    <footer className='py-5 sm:px-10 px-5'>
      <div className="screen-max-width">
        <div>
            <p className='font-semibold text-gray text-sm'>
                More ways to Shop:{" "}
                <span className='underline text-blue'>
                    Find an Apple Store
                </span>
                {" "}or{" "}
                <span className='underline text-blue'>
                    other retailer 
                </span>
                {" "}near you.
            </p>
            <p className='font-semibold text-gray text-sm'>
                Or call 800-999-0000
            </p>
        </div>
        <div className='bg-neutral-700 my-5 h-[1px] w-full'/>
        <div className="flex md:flex-row flex-col md:items-center justify-between">
            <p className='font-semibold text-gray text-sm'> Copyright @ 2024 Apple Inc. All Rioghts reserved</p>
            <div className="flex">
                {footerLinks.map((link,i)=>(
                    <p className='font-semibold text-gray text-sm' key={link}>
                        {link}{' '}
                        {
                            i !== footerLinks.length-1 && (
                                <span className='mx-2'> | </span>
                            )
                        }
                    </p>
                ))}
            </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
